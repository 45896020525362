import {
  Button,
  FormControl,
  FormLabel,
  Image,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";

import { getFronteggErrorMessage } from "~/api/frontegg/fetch";
import { Alert } from "~/components/Alert";
import { CopyableBox } from "~/components/copyableComponents";
import { AuthContentContainer } from "~/layouts/AuthLayout";
import { useMfaEnroll, useMfaPolicyAllowRemember } from "~/queries/frontegg";

import { MfaFields } from "./MfaFields";

export interface EnrollAuthenticatorFormState {
  mfaToken: string;
  rememberDevice: boolean;
  token: string;
}

export const EnrollAuthenticator = ({
  mfaToken,
  recoveryCode,
  qrCode,
  mfaEnrolled,
  setMfaEnrolled,
}: {
  mfaToken: string;
  recoveryCode: string;
  qrCode: string;
  mfaEnrolled: boolean;
  setMfaEnrolled: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { formState, handleSubmit, register } =
    useForm<EnrollAuthenticatorFormState>({
      defaultValues: {
        mfaToken: mfaToken,
        token: "",
        rememberDevice: true,
      },
      mode: "onTouched",
    });
  const { data: mfaPolicy } = useMfaPolicyAllowRemember({
    mfaToken,
  });
  const {
    error,
    isError,
    isPending,
    isSuccess,
    mutate: mfaEnroll,
  } = useMfaEnroll();

  const handleValidSubmit = (values: EnrollAuthenticatorFormState) => {
    mfaEnroll({ ...values, mfaToken });
  };

  if (isSuccess) {
    return (
      <RecoveryCode
        recoveryCode={recoveryCode}
        mfaEnrolled={mfaEnrolled}
        setMfaEnrolled={setMfaEnrolled}
      />
    );
  }
  return (
    <AuthContentContainer
      title="Setup authenticator"
      subheading={
        <>
          <Text>
            Use your authenticator app to scan the QR code. Then enter the
            generated six-digit code below.
          </Text>
        </>
      }
    >
      <form onSubmit={handleSubmit(handleValidSubmit)}>
        <VStack spacing="6" alignItems="start" width="100%">
          {isError && (
            <Alert
              variant="error"
              message={
                getFronteggErrorMessage(error) ?? "Authenticator setup failed."
              }
              width="100%"
            />
          )}
          <Image alignSelf="center" src={qrCode} height="260px" width="260px" />
          <MfaFields
            register={register}
            formState={formState}
            mfaToken={mfaToken}
            mfaDeviceExpiration={mfaPolicy.mfaDeviceExpiration}
          />
          <Button
            alignSelf="flex-end"
            variant="primary"
            size="lg"
            type="submit"
            isLoading={isPending}
            spinner={<Spinner />}
          >
            Verify
          </Button>
        </VStack>
      </form>
    </AuthContentContainer>
  );
};

export const RecoveryCode = ({
  recoveryCode,
  mfaEnrolled,
  setMfaEnrolled,
}: {
  recoveryCode: string;
  mfaEnrolled: boolean;
  setMfaEnrolled: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <AuthContentContainer
      title="MFA recovery code"
      subheading={
        <Text>
          The recovery code can be used to disable multifactor authentication in
          case you loose your authenticator device.
        </Text>
      }
    >
      <VStack spacing="6" alignItems="start" width="100%">
        <Alert
          variant="info"
          message="Copy and save this code, we won't show it again."
          width="100%"
        />
        <FormControl>
          <FormLabel>Recovery code</FormLabel>
          <CopyableBox contents={recoveryCode} width="100%" />
        </FormControl>
        <Button
          alignSelf="flex-end"
          variant="primary"
          size="lg"
          type="submit"
          isLoading={mfaEnrolled}
          spinner={<Spinner />}
          onClick={() => setMfaEnrolled(true)}
        >
          Done
        </Button>
      </VStack>
    </AuthContentContainer>
  );
};
