import type { Tenant, User } from "~/api/frontegg/types";
import { CloudRegion } from "~/store/cloudRegions";

import { getLaunchDarklyKey, getSegmentApiKey } from "./apiKeys";
import {
  getCloudGlobalApiUrl,
  getEnvironmentdScheme,
  getFronteggUrl,
} from "./apiUrls";
import { buildConstants } from "./buildConstants";
import { getCloudRegions } from "./cloudRegions";
import { getConsoleEnvironment } from "./consoleEnvironment";
import { getCurrentStack } from "./currentStack";
import { getEnvironmentdConfig } from "./environment";
import {
  getImpersonatedEnvironment,
  ImpersonationConfig,
} from "./impersonation";
import { getTempoDatasourceIds } from "./tempo";

export class AppConfig {
  /**
   * The base name of the console e.g. "internal-console"
   */
  basename: string;
  /**
   * The global API URL for Materialize Cloud. null in flexible deployment mode.
   */
  cloudGlobalApiUrl: string | null;
  cloudRegionsOverride: CloudRegion[] | null;
  /** The current cloud stack e.g. "local" or "production".
   * For Vitest, we stub config as 'test' via a Vitest file mock.
   * Prefer a dedicated config value instead of using this directly
   *
   * null - Console isn't using any Materialize Cloud resources. Seen in flexible deployment mode.
   * */
  currentStack: string | null;
  /**
   * Allows Console to point at a specific environmentd instance. Used for impersonation and flexible deployment mode.
   *
   * null - Console is using environmentd instance given by the region API.
   */
  environmentdOverride: {
    environmentdHttpAddress: string;
    regionId: string;
  } | null;
  /** The scheme that should be used. Local dev now requires SSL (unless one is bypassing the load balancer) */
  environmentdScheme: "http" | "https";
  environmentdWebsocketScheme: "ws" | "wss";
  /**
   * Frontegg API URL for the current environment.
   * null in flexible deployment mode.
   */
  fronteggUrl: string | null;
  /**
   * The tag of the Materialize Console image.
   * null in mz-cloud mode.
   */
  mzConsoleImageTag: string | null;
  /**
   * Region and environment details used during user organization impersonation.
   * Value is null during normal console usage or flexible deployment mode.
   */
  impersonation: ImpersonationConfig;
  isImpersonating: boolean;
  /**
   * LaunchDarkly key for the current environment.
   * null in flexible deployment mode.
   */
  launchDarklyKey: string | null;
  queryRetriesEnabled: boolean;
  /**
   * Segment API key for the current environment.
   * null in flexible deployment mode.
   */
  segmentApiKey: string | null;

  /**
   * Sentry configuration for the current environment.
   * null in flexible deployment mode.
   */
  sentryConfig: {
    dsn: string;
    environment: string;
    release: string;
  } | null;
  /**
   * The stack switcher is a widget that allows users to switch between cloud stacks.
   */
  stackSwitcherEnabled: boolean;
  /**
   * Returns a stub of the tenants for impersonation or flexible deployment mode.
   */
  tenantsStub: {
    tenants: Tenant[];
    activeTenant: Tenant;
  } | null;

  /**
   * Returns a stub of the user for impersonation or flexible deployment mode.
   */
  userStub: User | null;

  tempoDatasourceIds: Record<string, string> | null;
  #consoleEnvironment:
    | ReturnType<typeof getConsoleEnvironment>
    | "flexible-deployment";
  /**
   * Are we in a browser? If not, we can assume this is the Playwright node test process.
   *
   * Note that this is *true* in vite with jsdom.
   */
  #isBrowser: boolean;
  #consoleUrl: Location | URL;

  constructor() {
    this.#isBrowser =
      typeof window !== "undefined" && typeof window.document !== "undefined";
    this.#consoleUrl = this.#isBrowser
      ? location
      : new URL(
          process.env.CONSOLE_ADDR || "http://local.dev.materialize.com:3000",
        );

    if (buildConstants.consoleDeploymentMode === "flexible-deployment") {
      this.currentStack = null;
      this.cloudGlobalApiUrl = null;
      this.impersonation = null;
      this.isImpersonating = false;
      this.sentryConfig = null;
      this.fronteggUrl = null;
      this.launchDarklyKey = null;

      this.segmentApiKey = null;
      this.stackSwitcherEnabled = false;
      this.tempoDatasourceIds = null;
      this.mzConsoleImageTag = buildConstants.mzConsoleImageTag ?? null;
      this.#consoleEnvironment = "flexible-deployment";
    } else {
      this.mzConsoleImageTag = null;
      this.currentStack = getCurrentStack({
        hostname: this.#consoleUrl.hostname,
        isBrowser: this.#isBrowser,
      });
      this.impersonation = getImpersonatedEnvironment(
        this.#consoleUrl.host,
        buildConstants,
      );
      this.isImpersonating = Boolean(this.impersonation);
      this.#consoleEnvironment = getConsoleEnvironment({
        hostname: this.#consoleUrl.hostname,
        isImpersonating: this.isImpersonating,
      });

      this.cloudGlobalApiUrl = getCloudGlobalApiUrl({
        stack: this.currentStack,
        isImpersonation: this.isImpersonating,
      });
      this.fronteggUrl = getFronteggUrl(this.currentStack);
      this.launchDarklyKey = getLaunchDarklyKey(this.#consoleEnvironment);

      this.sentryConfig = {
        dsn: "https://13c8b3a8d1e547c9b9493de997b04337@o561021.ingest.sentry.io/5699757",
        environment: this.currentStack,
        release: buildConstants.sentryRelease,
      };

      this.segmentApiKey = getSegmentApiKey(this.#consoleEnvironment);

      this.stackSwitcherEnabled =
        !this.isImpersonating && this.#consoleEnvironment !== "production";

      this.tempoDatasourceIds = getTempoDatasourceIds(this.currentStack);
    }

    this.basename = buildConstants.basename;

    this.cloudRegionsOverride = getCloudRegions({
      stack: this.currentStack,
      impersonation: this.impersonation,
      isFlexibleDeployment:
        buildConstants.consoleDeploymentMode === "flexible-deployment",
    });

    this.environmentdOverride = getEnvironmentdConfig({
      consoleHost: this.#consoleUrl.host,
      cloudRegionOverride: this.cloudRegionsOverride?.[0],
      impersonation: this.impersonation,
    });

    const isLocalImpersonation = Boolean(buildConstants.impersonationHostname);

    const [httpScheme, websocketScheme] = getEnvironmentdScheme({
      buildConstants,
      isLocalImpersonation,
    });

    this.environmentdScheme = httpScheme;
    this.environmentdWebsocketScheme = websocketScheme;

    this.queryRetriesEnabled = buildConstants.defaultStack !== "test";

    // We mock the current frontegg user if there's no auth provider.
    if (!this.hasAuthProvider) {
      this.userStub = {
        id: "1",
        name: this.isImpersonating ? "Impersonation User" : "Default User",
        email: this.isImpersonating
          ? "impersonation@materialize.com"
          : "local@materialize.com",
        tenantId: "",
        profilePictureUrl: "",
        sub: "",
        verified: true,
        phoneNumber: null,
        provider: "",
        mfaEnrolled: false,
        metadata: "",
        vendorMetadata: null,
        tenantIds: [],
        roles: [],
        permissions: [],
        createdAt: "",
        lastLogin: "",
        isLocked: false,
        tenants: [],
        managedBy: "frontegg",
        groups: [],
        subAccountAccessAllowed: false,
        activatedForTenant: true,
      };
    } else {
      this.userStub = null;
    }

    // We mock the current organization for flexible deployment and local impersonation
    // since both can't use the global organizations API.
    if (this.isFlexibleDeployment || isLocalImpersonation) {
      const tenantStub = {
        _id: "1",
        vendorId: "1",
        tenantId: this.impersonation?.organizationId ?? "default-tenant-id",
        name: this.impersonation?.organizationId ?? "Default Organization",
        deletedAt: null,
        metadata: "",
        isReseller: false,
        creatorEmail: "user@materialize.com",
        creatorName: "user",
        id: this.impersonation?.organizationId || "default-organization-id",
        createdAt: new Date(),
        updatedAt: new Date(),
        __v: 1,
        roles: [],
        expirationDate: null,
      };
      this.tenantsStub = { tenants: [tenantStub], activeTenant: tenantStub };
    } else {
      this.tenantsStub = null;
    }
  }

  /** The base url of console e.g. https://console.materialize.com */
  get consoleAddress() {
    return `${this.#consoleUrl.protocol}//${this.#consoleUrl.hostname}${
      this.#consoleUrl.port ? ":" + this.#consoleUrl.port : ""
    }`;
  }

  get requiresExternalRegistration() {
    return this.#consoleEnvironment === "production";
  }

  get hasBillingPermissionOverride() {
    return this.isImpersonating;
  }

  get isFlexibleDeployment() {
    return buildConstants.consoleDeploymentMode === "flexible-deployment";
  }

  get hasAuthProvider() {
    return !this.isFlexibleDeployment && !this.isImpersonating;
  }
}

export const appConfig = new AppConfig();
