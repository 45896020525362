import { Checkbox, FormControl, Input, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { FormState, UseFormRegister } from "react-hook-form";

type MfaFieldsState = {
  /** Secret key provided by Frontegg */
  mfaToken: string;
  rememberDevice: boolean;
  /** One time code generated by the authenticator app */
  token: string;
};

export const MfaFields = (props: {
  register: UseFormRegister<MfaFieldsState>;
  formState: FormState<MfaFieldsState>;
  mfaToken: string;
  mfaDeviceExpiration: number | null;
}) => {
  return (
    <VStack spacing="6" alignItems="start" width="100%">
      <FormControl isInvalid={!!props.formState.errors.token}>
        <Input
          {...props.register("token", {
            required: "MFA token is required.",
          })}
          autoCorrect="off"
          size="lg"
          variant={props.formState.errors.token ? "error" : "default"}
          aria-label="Authenticator token"
        />
      </FormControl>
      {props.mfaDeviceExpiration ? (
        <FormControl>
          <Checkbox
            {...props.register("rememberDevice")}
            variant={props.formState.errors.token ? "error" : "default"}
            width="100%"
          >
            <Text textStyle="text-small">
              Don&apos;t ask again on this device for{" "}
              {props.mfaDeviceExpiration / 60 / 60 / 24} days
            </Text>
          </Checkbox>
        </FormControl>
      ) : null}
    </VStack>
  );
};
