import {
  Button,
  chakra,
  HStack,
  Image,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import React from "react";

import githubLightPath from "~/img/github-mark.svg";
import githubDarkPath from "~/img/github-mark-white.svg";
import googleIconPath from "~/img/google-mark.svg";
import { useOauthConfiguration } from "~/queries/frontegg";

import {
  buildGithubOauthRedirect,
  buildGoogleOauthRedirect,
  getAntiForgeryToken,
} from "./oauthLogin";

export const OauthLoginButtons = () => {
  const { colorMode } = useColorMode();
  const { data: oauthConfig } = useOauthConfiguration();

  const githubIconPath =
    colorMode === "dark" ? githubDarkPath : githubLightPath;
  const googleSsoConfig = oauthConfig.find(
    (config) => config.type === "google",
  );
  const githubSsoConfig = oauthConfig.find(
    (config) => config.type === "github",
  );
  const antiForgeryToken = React.useMemo(() => {
    return getAntiForgeryToken();
  }, []);

  if (!oauthConfig.some((config) => config.active)) return null;

  return (
    <>
      <HStack width="100%" spacing="4">
        <chakra.hr width="100%" />
        <Text flexShrink="0" color="foreground.secondary">
          Or with
        </Text>
        <chakra.hr width="100%" />
      </HStack>
      <VStack width="100%">
        {githubSsoConfig?.active && (
          <Button
            as="a"
            width="100%"
            href={buildGithubOauthRedirect(githubSsoConfig, antiForgeryToken)}
          >
            <Image src={githubIconPath} h={4} w={4} mr="2" />
            Github
          </Button>
        )}
        {googleSsoConfig?.active && (
          <Button
            as="a"
            width="100%"
            href={buildGoogleOauthRedirect(googleSsoConfig, antiForgeryToken)}
          >
            <Image src={googleIconPath} h={4} w={4} mr="2" />
            Google
          </Button>
        )}
      </VStack>
    </>
  );
};
