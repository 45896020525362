import { ActiveSsoConfiguration } from "~/api/frontegg/types";
import storageAvailable from "~/utils/storageAvailable";

export const ANTI_FORGERY_KEY = "mz-oauth-state";

const GOOGLE_SCOPES = [
  "https://www.googleapis.com/auth/userinfo.profile",
  "https://www.googleapis.com/auth/userinfo.email",
];

const GITHUB_SCOPES = ["read:user", "user:email"];

const GOOGLE_OAUTH_URL = "https://accounts.google.com/o/oauth2/v2/auth";
const GITHUB_OAUTH_URL = "https://github.com/login/oauth/authorize";

export function getAntiForgeryToken() {
  if (!storageAvailable("localStorage"))
    // If we don't have access to local storage, we have to fallback to a static value
    return "1m8tekm1ezan211z0xv5f4k3w45xqiyiv1vjhyr9u32kyu1yo70rt1eb715ep0nhbh";

  let value = localStorage.getItem(ANTI_FORGERY_KEY);
  if (!value) {
    value = createAntiForgeryToken();
    localStorage.setItem(ANTI_FORGERY_KEY, value);
  }
  return value;
}

export function createAntiForgeryToken() {
  const array = new Uint32Array(10);
  crypto.getRandomValues(array);
  return Array.from(array, (dec) => dec.toString(36)).join("");
}

export function buildGoogleOauthRedirect(
  ssoConfig: ActiveSsoConfiguration,
  antiForgeryToken: string,
) {
  return buildOauthRedirectUrl({
    baseUrl: GOOGLE_OAUTH_URL,
    baseScopes: GOOGLE_SCOPES,
    state: JSON.stringify({
      oauthState: antiForgeryToken,
      provider: "google",
      action: "login",
    }),
    ...ssoConfig,
  });
}
export function buildGithubOauthRedirect(
  ssoConfig: ActiveSsoConfiguration,
  antiForgeryToken: string,
) {
  return buildOauthRedirectUrl({
    baseUrl: GITHUB_OAUTH_URL,
    baseScopes: GITHUB_SCOPES,
    state: JSON.stringify({
      oauthState: antiForgeryToken,
      provider: "github",
      action: "login",
    }),
    ...ssoConfig,
  });
}

export function buildOauthRedirectUrl({
  baseUrl,
  clientId,
  redirectUrl,
  baseScopes,
  additionalScopes,
  state,
}: {
  baseUrl: string;
  clientId: string;
  redirectUrl: string;
  baseScopes: string[];
  additionalScopes: string[];
  state: string;
}) {
  const url = new URL(baseUrl);
  url.searchParams.append("redirect_uri", redirectUrl);
  url.searchParams.append("response_type", "code");
  url.searchParams.append("include_granted_scopes", "true");
  url.searchParams.append(
    "scope",
    baseScopes.concat(additionalScopes ?? []).join(" "),
  );
  url.searchParams.append("state", state);
  url.searchParams.append("client_id", clientId);
  return url.toString();
}

export function validatateOauthToken(token: unknown) {
  if (!token) return false;
  return token === getAntiForgeryToken();
}
