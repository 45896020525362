import React from "react";
import { useNavigate } from "react-router-dom";

import { loginPath } from "~/platform/routeHelpers";
import { useLogout } from "~/queries/frontegg";

export const Logout = () => {
  const navigate = useNavigate();
  const { mutate: logout } = useLogout({
    // If this fails for some reason, the user is most likely logged out already.
    onSettled: () => {
      navigate(loginPath, { replace: true });
    },
  });

  React.useEffect(logout, [logout]);

  return null;
};
