import { ExpressionBuilder, sql, StringReference } from "kysely";

import { queryBuilder } from "~/api/materialize/db";
import {
  QUERY_HISTORY_LIST_TABLE,
  QUERY_HISTORY_LIST_TABLE_REDACTED,
} from "~/api/materialize/query-history/constants";
import { DB, Interval } from "~/types/materialize";

function buildSearchPathCommonColumn(
  eb: ExpressionBuilder<
    DB,
    "mz_recent_activity_log_redacted" | "mz_recent_activity_log"
  >,
) {
  // We need to cast the search_path to string[] because it's a custom Materialize list type,
  // which becomes typed as a string in Kysely codegen.
  return eb.ref("search_path").$castTo<string[]>().as("search_path");
}

export function buildActivityLogTable({
  showRedacted,
}: {
  showRedacted: boolean;
}) {
  const commonColumns = [
    "application_name",
    "authenticated_user",
    "database_name",
    "cluster_name",
    "mz_version",
    "execution_id",
    "execution_strategy",
    "finished_status",
    "session_id",
    "cluster_id",
    "statement_type",
    "transaction_isolation",
    "finished_at",
    "prepared_at",
    "began_at",
    "rows_returned",
  ] as const;

  if (showRedacted) {
    return queryBuilder
      .selectFrom(QUERY_HISTORY_LIST_TABLE_REDACTED)
      .select([
        ...commonColumns,
        (eb) => buildSearchPathCommonColumn(eb),
        (eb) => eb.lit(null).as("error_message"),
        "redacted_sql as sql",
      ]);
  } else {
    return queryBuilder
      .selectFrom(QUERY_HISTORY_LIST_TABLE)
      .select([
        ...commonColumns,
        (eb) => buildSearchPathCommonColumn(eb),
        "error_message",
        "sql",
      ]);
  }
}

export function buildFinishedStatusSelection<DB, TB extends keyof DB>(
  eb: ExpressionBuilder<DB, TB>,
  ref: StringReference<DB, TB>,
) {
  return eb.fn.coalesce(ref, sql<string>`'running'`).$castTo<string>(); // NULL is treated as running
}

export function buildDurationSelection<DB, TB extends keyof DB>(
  eb: ExpressionBuilder<DB, TB>,
  finishedAtRef: StringReference<DB, TB>,
  beganAtRef: StringReference<DB, TB>,
) {
  return eb(finishedAtRef, "-", eb.ref(beganAtRef)).$castTo<Interval | null>(); // Kysely processes the subtraction of Timestamp types as Timestamp
}
