import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Alert from "~/components/Alert";
import { LoadingContainer } from "~/components/LoadingContainer";
import { AuthLayout } from "~/layouts/AuthLayout";
import { useAcceptInvitation } from "~/queries/frontegg";

export const AcceptInvitation = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { error: acceptError, mutate: acceptInvitation } = useAcceptInvitation({
    onSuccess: () => {
      navigate("/", { replace: true });
    },
  });
  const token = params.get("token");
  const userId = params.get("userId");

  React.useEffect(() => {
    if (!token || !userId) return;

    acceptInvitation({ token, userId });
  }, [acceptInvitation, token, userId]);

  return (
    <AuthLayout>
      {acceptError ? (
        <Alert
          variant="error"
          message="An error has occurred accepting this invitation."
        />
      ) : !token || !userId ? (
        <Alert variant="error" message="This invitation is invalid." />
      ) : (
        <LoadingContainer />
      )}
    </AuthLayout>
  );
};
