import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { loginPath } from "~/platform/routeHelpers";
import { useOauthLogin } from "~/queries/frontegg";

import { validatateOauthToken } from "./oauthLogin";

export const OauthCallback = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const loginCalled = React.useRef(false);
  const { mutate: oauthLogin } = useOauthLogin();

  React.useEffect(() => {
    if (loginCalled.current) return;

    const code = params.get("code");
    const state = params.get("state");

    if (!code || !state) {
      navigate(loginPath);
      return;
    }
    const decodedState = tryParseState(state);
    if (
      !decodedState ||
      !decodedState.provider ||
      !validatateOauthToken(decodedState.oauthState)
    ) {
      console.error(decodedState);
      console.error("OAuth state did not match");
      navigate(loginPath);
      return;
    }
    oauthLogin(
      {
        code,
        state,
        provider: decodedState.provider.toString(),
        redirectUri: location.origin + location.pathname,
      },
      {
        onError: (e) => {
          console.log(e);
          navigate(loginPath, { replace: true });
        },
        onSuccess: () => {
          console.log("success");
          navigate("/", { replace: true });
        },
      },
    );
    loginCalled.current = true;
  }, [navigate, oauthLogin, params]);

  return null;
};

function tryParseState(json: string) {
  try {
    const result = JSON.parse(json);
    if (!result || typeof result !== "object") return null;
    return result as Record<string, unknown>;
  } catch (e) {
    return null;
  }
}
