import { useTimeout } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import { Alert } from "~/components/Alert";

export const AccountActivationSuccess = () => {
  const navigate = useNavigate();
  useTimeout(() => {
    navigate("/", { replace: true });
  }, 3000);

  return (
    <Alert
      variant="info"
      label="Account activated"
      message="Your account has been successfully activated, you will now be logged in."
    />
  );
};
