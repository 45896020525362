import {
  Button,
  FormControl,
  Input,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { getFronteggErrorMessage } from "~/api/frontegg/fetch";
import Alert from "~/components/Alert";
import { LabeledInput } from "~/components/formComponentsV2";
import TextLink from "~/components/TextLink";
import { AuthContentContainer, AuthLayout } from "~/layouts/AuthLayout";
import { loginPath } from "~/platform/routeHelpers";
import { useResetPassword } from "~/queries/frontegg";

import { PasswordField } from "./PasswordField";
import { validatePassword } from "./utils";

type ResetPasswordFormState = {
  password: string;
  confirmPassword: string;
};

export const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId") ?? "";
  const token = searchParams.get("token") ?? "";
  const { formState, handleSubmit, register } = useForm<ResetPasswordFormState>(
    {
      criteriaMode: "all",
      defaultValues: {
        password: "",
      },
      mode: "onTouched",
    },
  );
  const {
    error,
    isSuccess,
    isPending,
    mutate: resetPassword,
  } = useResetPassword({
    onSuccess: () => {
      setTimeout(() => {
        navigate(loginPath, { replace: true });
      }, 3000);
    },
  });

  const handleValidSubmit = ({ password }: ResetPasswordFormState) => {
    resetPassword({ password, userId, token });
  };

  return (
    <AuthLayout>
      {isSuccess ? (
        <AuthContentContainer
          title="Password changed"
          subheading={
            <Text>
              You can now log in with your new password, the login page will
              appear shortly.
            </Text>
          }
        >
          <TextLink as={Link} to={loginPath}>
            Sign in
          </TextLink>
        </AuthContentContainer>
      ) : (
        <AuthContentContainer title="Reset password">
          <form onSubmit={handleSubmit(handleValidSubmit)}>
            <VStack spacing="6" alignItems="start">
              {error && (
                <Alert
                  variant="error"
                  message={getFronteggErrorMessage(error, "Reset failed")}
                  width="100%"
                />
              )}
              <PasswordField
                label="New password"
                errors={formState.errors.password}
                inputProps={register("password", {
                  validate: validatePassword(),
                })}
              />
              <FormControl isInvalid={!!formState.errors.confirmPassword}>
                <LabeledInput
                  label="Confirm new password"
                  error={formState.errors.confirmPassword?.message}
                  inputBoxProps={{
                    maxWidth: "100%",
                  }}
                >
                  <Input
                    {...register("confirmPassword", {
                      required: "Confirm password is required.",
                      validate: {
                        match: (value, formValues) =>
                          formValues.password !== value
                            ? "Passwords must match."
                            : undefined,
                      },
                    })}
                    autoCorrect="off"
                    placeholder="Re-enter password"
                    size="lg"
                    type="password"
                    variant={
                      formState.errors.confirmPassword ? "error" : "default"
                    }
                  />
                </LabeledInput>
              </FormControl>

              <Button
                variant="primary"
                size="lg"
                type="submit"
                isLoading={isPending}
                spinner={<Spinner />}
                width="100%"
              >
                Reset password
              </Button>
              <TextLink as={Link} to={loginPath}>
                Back to sign in
              </TextLink>
            </VStack>
          </form>
        </AuthContentContainer>
      )}
    </AuthLayout>
  );
};
