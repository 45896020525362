import {
  Button,
  FormControl,
  Input,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";

import { FronteggApiError } from "~/api/frontegg/fetch";
import Alert from "~/components/Alert";
import { LabeledInput } from "~/components/formComponentsV2";
import TextLink from "~/components/TextLink";
import { AuthContentContainer, AuthLayout } from "~/layouts/AuthLayout";
import { loginPath } from "~/platform/routeHelpers";
import { useForgotPassword } from "~/queries/frontegg";

type ForgotPasswordFormState = {
  email: string;
};

export const ForgotPassword = () => {
  const [searchParams] = useSearchParams();
  const { formState, handleSubmit, register, watch } =
    useForm<ForgotPasswordFormState>({
      defaultValues: {
        email: searchParams.get("email") ?? "",
      },
      mode: "onTouched",
    });
  const email = watch("email");
  const {
    error,
    isSuccess,
    isPending,
    mutate: forgotPassword,
  } = useForgotPassword();

  const errorMessage =
    (error && error instanceof FronteggApiError && error.details.errors[0]) ||
    "An error occurred";

  const handleValidSubmit = (values: ForgotPasswordFormState) => {
    forgotPassword(values);
  };

  return (
    <AuthLayout>
      {isSuccess ? (
        <AuthContentContainer
          title="Check your inbox"
          subheading={
            <Text>
              We&apos;ve sent an email to {email} with instructions for
              resetting your password.
            </Text>
          }
        >
          <TextLink as={Link} to={loginPath}>
            Back to sign in
          </TextLink>
        </AuthContentContainer>
      ) : (
        <AuthContentContainer
          title="Forgot password"
          subheading={
            <Text>
              Enter your email address and we&apos;ll send you a link to reset
              your password.
            </Text>
          }
        >
          <form onSubmit={handleSubmit(handleValidSubmit)}>
            <VStack spacing="6" alignItems="start">
              {error && (
                <Alert variant="error" message={errorMessage} width="100%" />
              )}
              <FormControl isInvalid={!!formState.errors.email}>
                <LabeledInput
                  label="Email"
                  error={formState.errors.email?.message}
                  variant="stretch"
                >
                  <Input
                    {...register("email", {
                      required: "Email is required.",
                    })}
                    autoCorrect="off"
                    placeholder="name@example.com"
                    size="lg"
                    variant={formState.errors.email ? "error" : "default"}
                  />
                </LabeledInput>
              </FormControl>

              <Button
                variant="primary"
                size="lg"
                type="submit"
                isLoading={isPending}
                spinner={<Spinner />}
                width="100%"
              >
                Reset password
              </Button>
              <TextLink as={Link} to={loginPath}>
                Back to sign in
              </TextLink>
            </VStack>
          </form>
        </AuthContentContainer>
      )}
    </AuthLayout>
  );
};
