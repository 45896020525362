import { jwtDecode } from "jwt-decode";

import { getFronteggToken } from "~/api/fronteggToken";
import { currentTenantId, setCurrentTenantId } from "~/utils/frontegg";

import {
  appendAuthHeader,
  assertResponseOk,
  fetchAndThrowOnError,
  fetchAndThrowOnErrorWithoutTokenRefresh,
  fetchJson,
  fetchJsonWithoutTokenRefresh,
  fronteggFetchWithoutTokenRefetch,
} from "./fetch";
import {
  ActivateAccountParams,
  ActivateAccountResponse,
  ActivateStrategy,
  ActivateStrategyParams,
  LoginSuccess,
  MfaEnrollParams,
  MfaEnrollResponse,
  MfaPolicy,
  MfaPolicyAllowRememberParams,
  MfaPolicyAllowRememberResponse,
  MfaPolicyResponse,
  MfaRequiredResponse,
  NewTenantApiToken,
  NewUserApiToken,
  PreloginResult,
  PreloginSuccess,
  Role,
  SignupParams,
  SignupResponse,
  SocialLoginSuccess,
  SsoConfiguration,
  TenantApiToken,
  TenantsResponse,
  User,
  UserApiToken,
  VerifyMfaParams,
  VerifyMfaResponse,
} from "./types";
import { TokenRefreshResponse } from "./types";

export async function acceptInvitation(
  params: { userId: string; token: string },
  requestOptions?: RequestInit,
) {
  return fetchAndThrowOnErrorWithoutTokenRefresh(
    "/frontegg/identity/resources/users/v1/invitation/accept",
    {
      method: "post",
      body: JSON.stringify(params),
      ...requestOptions,
    },
  );
}

export async function activateAccount(
  params: ActivateAccountParams,
  requestOptions?: RequestInit,
) {
  return fetchJsonWithoutTokenRefresh(
    "/frontegg/identity/resources/users/v1/activate",
    {
      method: "post",
      // The response includes a refresh cookie
      credentials: "include",
      body: JSON.stringify(params),
      ...requestOptions,
    },
  ) as Promise<ActivateAccountResponse>;
}

export async function activateStrategy(
  params: ActivateStrategyParams,
  requestOptions?: RequestInit,
) {
  return fetchJsonWithoutTokenRefresh(
    "/frontegg/identity/resources/users/v1/activate/strategy?" +
      new URLSearchParams(params).toString(),
    requestOptions,
  ) as Promise<ActivateStrategy>;
}

function updateTenantId(user: User) {
  const tenantId = currentTenantId();
  if (!tenantId) return;

  if (user.tenants.find((t) => t.tenantId === tenantId)) {
    // The user response always includes the active tenant according to the server, which
    // is the tenant the user last switched to. Since we support separate tenant per
    // session, we override that value here.
    //
    // In theory this shouldn't be required, since this request was made with a token
    // for the same tenant. Frontegg's own client code does this in
    // @frontegg/rest-api/node/auth/index.js in getMeAndEntitlements. So we decided to
    // match that behavior.
    user.tenantId = tenantId;
  } else {
    // If the user isn't in the locally stored tenant, reset it to the users current
    // tenant according to Frontegg.
    setCurrentTenantId(user.tenantId);
  }
}

export async function fetchUser(requestOptions?: RequestInit) {
  // We return the roles and permissions from the Frontegg token rather than the
  // Frontegg API endpoint. The reason is that the cloud API and SQL requests that
  // a user can make are determined by the roles and permissions in the token, not
  // the roles and permissions returned by the Frontegg API. When we make an API
  // request to our cloud backend (e.g., the usage and billing page), or launch a
  // SQL shell, the backend will only consider the roles and permissions in the
  // token.

  // The reason the Frontegg token and Frontegg API can get out of sync is because
  // tokens can be up to ten minutes old. The token reflects the roles and
  // permissions from when the token was issued, while the API reflects the roles
  // and permissions as of the current moment.

  // If it becomes necessary, we could keep track of both sets of roles and
  // permissions here, e.g. `rolesFromToken` and `rolesFromApi`. For now, however,
  // every user of this information in the Console should look at the roles and
  // permissions from the token, so to keep things simple we simply replace the
  // roles and permissions from the API with the roles and permissions from the
  // token.
  const {
    permissions: _permissions,
    roles: _roles,
    ...userInfo
  } = await fetchJson(
    "/frontegg/identity/resources/users/v2/me",
    requestOptions,
  );

  const user: User = {
    ...userInfo,
    permissions: [],
    roles: [],
  };

  try {
    // We don't need to validate the signature of the token because we received the token
    // directly from a trusted source (Frontegg)."
    const token = jwtDecode(getFronteggToken() ?? "") as {
      roles: string[] | undefined;
      permissions: string[] | undefined;
    };

    if (token.roles) {
      user.roles = token.roles;
    }
    if (token.permissions) {
      user.permissions = token.permissions;
    }
  } catch {
    /* empty */
  }
  updateTenantId(user);

  return user as User;
}

export async function fetchRefreshAccessToken(requestOptions?: RequestInit) {
  const tenantId = currentTenantId();
  return fetchJsonWithoutTokenRefresh(
    `/frontegg/identity/resources/auth/v1/user/token/refresh`,
    {
      method: "post",
      credentials: "include",
      body: tenantId ? JSON.stringify({ tenantId }) : undefined,
      ...requestOptions,
    },
  ) as Promise<TokenRefreshResponse>;
}

export async function fetchRoles(requestOptions?: RequestInit) {
  return fetchJson(
    "/frontegg/identity/resources/roles/v1",
    requestOptions,
  ) as Promise<Role[]>;
}

export async function fetchUserApiTokens(requestOptions?: RequestInit) {
  const response = await fetchJson(
    "/frontegg/identity/resources/users/api-tokens/v1",
    requestOptions,
  );
  return response.map((props: any) => ({
    ...props,
    type: "personal",
  })) as Promise<Array<UserApiToken>>;
}

export async function createUserApiToken(
  params: { description: string },
  requestOptions?: RequestInit,
) {
  return fetchJson("/frontegg/identity/resources/users/api-tokens/v1", {
    method: "post",
    body: JSON.stringify(params),
    ...requestOptions,
  }) as Promise<NewUserApiToken>;
}

export async function deleteUserApiToken(
  params: { id: string },
  requestOptions?: RequestInit,
) {
  return fetchAndThrowOnError(
    `/frontegg/identity/resources/users/api-tokens/v1/${params.id}`,
    {
      method: "delete",
      ...requestOptions,
    },
  );
}

export async function fetchTenantApiTokens(requestOptions?: RequestInit) {
  const response = await fetchJson(
    "/frontegg/identity/resources/tenants/api-tokens/v1",
    requestOptions,
  );
  return response.map((props: any) => ({
    ...props,
    type: "service",
    user: props.metadata?.user,
  })) as Promise<Array<TenantApiToken>>;
}

export async function createTenantApiToken(
  { user, ...params }: { description: string; user: string; roleIds: string[] },
  requestOptions?: RequestInit,
) {
  return fetchJson("/frontegg/identity/resources/tenants/api-tokens/v1", {
    method: "post",
    body: JSON.stringify({
      metadata: {
        user,
      },
      ...params,
    }),
    ...requestOptions,
  }) as Promise<NewTenantApiToken>;
}

export async function updateTenantApiToken(
  params: { id: string; description: string; roleIds: string[] },
  requestOptions?: RequestInit,
) {
  return fetchJson(
    `/frontegg/identity/resources/tenants/api-tokens/v1/${params.id}`,
    {
      method: "patch",
      body: JSON.stringify(params),
      ...requestOptions,
    },
  ) as Promise<TenantApiToken>;
}

export async function deleteTenantApiToken(
  params: { id: string },
  requestOptions?: RequestInit,
) {
  return fetchAndThrowOnError(
    `/frontegg/identity/resources/tenants/api-tokens/v1/${params.id}`,
    {
      method: "delete",
      ...requestOptions,
    },
  );
}

export async function prelogin(
  params: { email: string },
  requestOptions?: RequestInit,
): Promise<PreloginResult> {
  // No need for token refresh here
  const response = await fronteggFetchWithoutTokenRefetch(
    `/frontegg/identity/resources/auth/v2/user/sso/prelogin`,
    {
      method: "post",
      body: JSON.stringify(params),
      ...requestOptions,
    },
  );
  if (response.status === 404) return Promise.resolve(false as const);
  assertResponseOk(response);
  return response.json() as Promise<PreloginSuccess>;
}

export async function forgotPassword(
  params: { email: string },
  requestOptions?: RequestInit,
) {
  return fetchAndThrowOnErrorWithoutTokenRefresh(
    `/frontegg/identity/resources/users/v1/passwords/reset`,
    {
      method: "post",
      body: JSON.stringify(params),
      ...requestOptions,
    },
  );
}

export type ResetPasswordParams = {
  password: string;
  token: string;
  userId: string;
};

export async function resetPassword(
  params: ResetPasswordParams,
  requestOptions?: RequestInit,
) {
  return fetchAndThrowOnErrorWithoutTokenRefresh(
    `/frontegg/identity/resources/users/v1/passwords/reset/verify`,
    {
      method: "post",
      body: JSON.stringify(params),
      ...requestOptions,
    },
  );
}

export async function login(
  params: { email: string; password: string },
  requestOptions?: RequestInit,
) {
  return fetchJsonWithoutTokenRefresh(
    `/frontegg/identity/resources/auth/v1/user`,
    {
      method: "post",
      credentials: "include",
      body: JSON.stringify(params),
      ...requestOptions,
    },
  ) as Promise<LoginSuccess | MfaRequiredResponse>;
}

export async function oauthConfiguration(requestOptions?: RequestInit) {
  return fetchJsonWithoutTokenRefresh(`/frontegg/identity/resources/sso/v2`, {
    ...requestOptions,
  }) as Promise<Array<SsoConfiguration>>;
}

export async function oidcPostlogin(
  params: { code: string; state: string },
  requestOptions?: RequestInit,
) {
  return fetchAndThrowOnErrorWithoutTokenRefresh(
    `/frontegg/identity/resources/auth/v2/user/oidc/postlogin`,
    {
      body: JSON.stringify({ code: params.code, RelayState: params.state }),
      method: "post",
      credentials: "include",
      ...requestOptions,
    },
  );
}

export async function setActiveTenant(params: { tenantId: string }) {
  return fetchJson("/frontegg/identity/resources/users/v1/tenant", {
    method: "put",
    body: JSON.stringify(params),
  }) as Promise<User>;
}

export type SocialPostloginParams = {
  code: string;
  state: string;
  redirectUri: string;
  provider: string;
};

export async function socialPostlogin(
  params: SocialPostloginParams,
  requestOptions?: RequestInit,
) {
  const searchParams = new URLSearchParams();
  searchParams.set("code", params.code);
  searchParams.set("redirectUri", params.redirectUri);
  searchParams.set("state", params.state);
  return fetchJsonWithoutTokenRefresh(
    `/frontegg/identity/resources/auth/v1/user/sso/${params.provider}/postlogin?` +
      searchParams.toString(),
    {
      method: "post",
      credentials: "include",
      ...requestOptions,
    },
  ) as Promise<SocialLoginSuccess>;
}

export async function signup(
  params: SignupParams,
  requestOptions?: RequestInit,
) {
  return fetchJsonWithoutTokenRefresh(
    `/frontegg/identity/resources/users/v1/signUp`,
    {
      body: JSON.stringify(params),
      method: "post",
      ...requestOptions,
    },
  ) as Promise<SignupResponse>;
}

export async function logout(requestOptions?: RequestInit) {
  return fetchAndThrowOnErrorWithoutTokenRefresh(
    `/frontegg/identity/resources/auth/v1/logout`,
    {
      method: "post",
      credentials: "include",
      ...requestOptions,
    },
  );
}

export async function fetchTenants(requestOptions?: RequestInit) {
  return fetchJson(
    "/frontegg/identity/resources/users/v3/me/tenants",
    requestOptions,
  ) as Promise<TenantsResponse>;
}

export async function fetchMfaPolicyAllowRemember(
  params: MfaPolicyAllowRememberParams,
  requestOptions?: RequestInit,
) {
  return fetchJsonWithoutTokenRefresh(
    `/frontegg/identity/resources/configurations/v1/mfa-policy/allow-remember-device?mfaToken=${params.mfaToken}`,
    requestOptions,
  ) as Promise<MfaPolicyAllowRememberResponse>;
}

export async function fetchMfaPolicy(
  requestOptions?: RequestInit,
): Promise<MfaPolicyResponse> {
  const response = await fronteggFetchWithoutTokenRefetch(
    `/frontegg/identity/resources/configurations/v1/mfa-policy`,
    appendAuthHeader(requestOptions ?? {}),
  );
  if (response.status === 404) {
    return { enforceMFAType: "NotSet" };
  }
  return response.json() as Promise<MfaPolicy>;
}

export async function mfaEnroll(
  params: MfaEnrollParams,
  requestOptions?: RequestInit,
) {
  return fetchJsonWithoutTokenRefresh(
    `/frontegg/identity/resources/auth/v1/user/mfa/authenticator/enroll/verify`,
    {
      method: "post",
      credentials: "include",
      body: JSON.stringify(params),
      ...requestOptions,
    },
  ) as Promise<MfaEnrollResponse>;
}

export async function verifyMfa(
  params: VerifyMfaParams,
  requestOptions?: RequestInit,
) {
  return fetchJsonWithoutTokenRefresh(
    `/frontegg/identity/resources/auth/v1/user/mfa/verify`,
    {
      method: "post",
      credentials: "include",
      body: JSON.stringify(params),
      ...requestOptions,
    },
  ) as Promise<VerifyMfaResponse>;
}
